.PresentationIng {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PresentationIng .sectionContainer {
    height: 90vh;
}
.PresentationIng .imageContainer {
    width: 45vw;
    
    // border: solid 1px red;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PresentationIng .imageContainer figure {
    display: flex;
    // align-items: center;
    justify-content: center;
    height: 80vh;
    width: 50vw;
    // border: solid 1px blue;
}
.PresentationIng .imageContainer figure img {
    z-index: 1;
    position: absolute;
    height: 75vh;
    width: 30vw;
    // border: solid white 5px;
    border-radius: 4px;
    filter: drop-shadow(0 0 8px rgb(82, 82, 82));
}
.PresentationIng .imageContainer .boxBack {
    height: 48vh;
    width: 40%;
    background-color: grey;
    position: relative;
    top: 26rem;
    left: 11rem;
}
.PresentationIng .imageContainer .boxFront {
    height: 12vh;
    width: 12vw;
    background-color: rgb(95, 95, 95);
    position: absolute;
    z-index: 2;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} 
.PresentationIng .imageContainer .boxFront p {
    color: white;
    font-size: 15px;
    padding: 10px;
    // font-weight: bold;
}
// .PresentationIng .imageContainer .boxFront :nth-child(1) {
//     font-size: 30px;
//     width: 90%;
//     border-bottom: solid 1px grey;
//     margin: 10px 0;
// }
.PresentationIng .constDescription {
    // border: solid 1px blue;
    width: 55vw;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
}
.PresentationIng .constDescription .texto {
    margin-top: 4rem;
    width: 50vw;
    height: 60%;
    // border: orange solid 1px;
}
.PresentationIng .constDescription .texto span{
    margin-bottom: 1rem;
    font-size: 1.6rem;
}
.PresentationIng .constDescription .texto h1 {
    font-size: 5rem;
}
.PresentationIng .constDescription .texto p{
    margin-top: 2rem;
    font-size: 2rem;
}
.PresentationIng .constDescription .hablemos {
    height: 60px;
    width: 150px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1.6rem;
}
.PresentationIng .constDescription .hablemos:hover {
    cursor: pointer;
    background-color: white;
    color: grey;
    filter: drop-shadow(0 0 8px grey);
}

@media (max-width: 1040px) {
    .PresentationIng .imageContainer {
        justify-content: flex-start;
        position: absolute;
        left: 4vw;
    }
    .PresentationIng .imageContainer figure {
        display: block;
        height: 80vh;
        width: 40vw;
    }
    .PresentationIng .imageContainer figure img {
        width: 38vw;
    }
    .PresentationIng .imageContainer .boxBack {
        width: 25vw;
        left: 16vw;
    }
    .PresentationIng .imageContainer .boxFront {
        width: 15vw;
    }
    .PresentationIng .constDescription {
        width: 46vw;
        position: absolute;
        right: 2vw;
    }
    .PresentationIng .constDescription .texto {
        width: 44vw;
    }

}

@media (max-width:500px) {
    .PresentationIng {
        flex-direction: column-reverse;
        height: 180vh;
    }
    .PresentationIng .constDescription {
        width: 85vw;
    }
    .PresentationIng .constDescription .texto {
        width: 100%;
        height: 85%;
    }
    .PresentationIng .imageContainer {
        width: 90vw;
    }
    .PresentationIng .imageContainer figure img {
        width: 90%;
        object-fit: cover;
    }
    .PresentationIng .imageContainer .boxBack {
        top: 35rem;
    }
    .PresentationIng .imageContainer .boxBack {
        width: 100%;
        height: 300px;
    }
    .PresentationIng .imageContainer .boxFront {
        width: 40vw;
    }
}