.HomeIntroPictures {
    display: flex;
    flex-direction: column;

    height: 169.1vh;
    // margin-bottom: 10rem;
    // border: solid 1px black;
    // z-index: -1;
    // position: relative;


}
.HomeIntroPictures .pictureBox {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.HomeIntroPictures > div:nth-child(1) {
    clip-path: polygon(0% 0%, 0 100%, 100% 70%, 100% 0);

    // border: solid 1px white;
}
.HomeIntroPictures > div:nth-child(2) {
    clip-path: polygon(100% 100%, 100% 0%, 0% 30%, 0 100%);
    
    // border: solid 1px blue;
}
.HomeIntroPictures .pictureBox .texto {
    color: white;
    font-size: 2rem;
    text-align: center;
    
    position: absolute;
    top: 20vh;
    left: 6vw;
    
    display: flex;
    flex-direction: column;
    
    filter: drop-shadow(0px 0px 8px black)
}
.HomeIntroPictures .pictureBox .texto a {
    width: fit-content;
    height: fit-content;
}
.HomeIntroPictures .pictureBox h1 {
    color: white;
    font-size: 7rem;
    text-align: center;

    padding: 0;
    padding-bottom: 2rem;
}


.HomeIntroPictures .pictureBox:nth-child(1) .texto {
    color: white;
    // border: solid 1px white;
}
.HomeIntroPictures .pictureBox:nth-child(1) .texto p{
    color: white;
    text-align: left;
    font-size: 2.4rem;
}
.HomeIntroPictures .pictureBox:nth-child(2) .texto {
    width: 86vw;
    // border: solid 1px red;

    margin: 0;
    
    display: flex;
    align-items: flex-end;
    // color: red;
    top: 34vh;
}
.HomeIntroPictures .pictureBox:nth-child(2) .texto p{
    color: white;
    text-align: left;
    font-size: 2.4rem;
    width: 650px;

    // border: solid 1px white;
}

.HomeIntroPictures figure{
    height: 110vh;
    width: 101%;
    filter: brightness(0.5);
    // position: absolute;
}
.HomeIntroPictures figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.HomeIntroPictures .pictureBox:nth-child(1) {
    position: sticky;
}
.HomeIntroPictures .pictureBox:nth-child(2) {
    position: relative;
    bottom: 30.1vh;
}

.HomeIntroPictures .pictureBox button {
    height: 65px;
    width: 135px;
    
    font-weight: 700;
    margin-top: 8vh;

    border: solid 4px rgba(0, 0, 0, 0.673);
    border-radius: 12px;
}
.HomeIntroPictures .pictureBox button:hover {
    cursor: pointer;

    background-color: white;
    border-color: white;

    filter: drop-shadow(0 0 8px white);

    font-style: italic;
    color: black;
}


@media (max-width: 500px) {
    .HomeIntroPictures {
        height: 190vh;
        width: 100vw;
    }
    .HomeIntroPictures .pictureBox {
        height: 110vh;
    }
    .HomeIntroPictures .pictureBox .texto {
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 0;
        width: 100vw;
    }
    .HomeIntroPictures .pictureBox .texto h1{
        width: 99%;
        font-size: 4.6rem;
    }
    .HomeIntroPictures .pictureBox .texto p{
        width: 99%;
        font-size: 2rem;
        text-align: center;
        padding: 0;
    }
    .HomeIntroPictures .pictureBox:nth-child(1) .texto p{
        text-align: center;
    }


    .HomeIntroPictures > div:nth-child(1) {
        clip-path: polygon(0% 0%, 0 90%, 100% 80%, 100% 0);
        position: static;
    }
    .HomeIntroPictures > div:nth-child(2) {
        position: absolute;
        // top: 80vh;
        clip-path: polygon(100% 100%, 100% 5%, 0% 15%, 0 100%);
    }

    .HomeIntroPictures .pictureBox:nth-child(2) .texto {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }
    .HomeIntroPictures .pictureBox:nth-child(2) .texto h1 {
        text-align: center;
    }
    .HomeIntroPictures .pictureBox:nth-child(2) .texto p {
        color: white;
        text-align: center;
        font-size: 2rem;
        width: auto;
        padding: 0 2rem;
    }
}