.ProjectData {
    height: 160px;
    width: 80vw;
    // min-width: 70vw;
    // border: solid 1px black;
    border-radius: 2rem;

    // background-color: var(--antiflash-white);
    background-color: rgba(0, 26, 86, 0.62);
    box-shadow: 0px 0px 10px 3px var(--penn-blue);


    position: absolute;

    // top: 650px;
    bottom: 6.2rem;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    color: white;
}
.ProjectData .infoBlock {
    height: 80%;
    border-left: solid 1px var(--antiflash-white);
    width: 23%;

    display: flex;
    flex-direction: column;
}
.ProjectData .infoBlock:nth-child(1) {
    width: 31%;   
    border: none;
}
.ProjectData .infoBlock h3 {
    font-size: 2.4rem;
    margin-left: 4rem;
    padding-top: 1rem;
    padding-bottom: .8rem;

    // align-self: center;
}
.ProjectData .infoBlock p {
    font-size: 1.4rem;
    margin: 0 4rem;
    padding-top: 1rem;
    padding-right: 2rem;
    border-top: solid 1px var(--antiflash-white);
}
.mobView {
    display: none;
}
@media (max-width: 1040px) {
    .ProjectData {
        width: 90vw;   
    }
    .ProjectData .infoBlock h3 {
        font-size: 2vw;
    }
    .ProjectData .infoBlock p {
        font-size: 1.2vw;
    }
}
@media (max-width: 500px) {
    .deskView {
        display: none;
    }
    .mobView{
        display: flex;
        flex-direction: column;
    }
    .ProjectData {
        width: 80vw;
    }
    .ProjectData .infoBlock h3 {
        font-size: 2.4rem;
    }
    .ProjectData .infoBlock p {
        font-size: 1.4rem;
    }
    .ProjectData .infoBlock:nth-child(1) {
        width: 100%;   
        border: none;
    }
    .ProjectData .infoBlock{
        width: 100%;   
        border: none;
    }
}