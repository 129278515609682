.AboutUs {
    height: 100vh;
    width: 100vw;
    // border: solid 1px red;

    display: flex;
}
.AboutUs .contentDescript {
    width: 50vw;
    height: 100vh;
    
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: flex-start;
}
.AboutUs .contentDescript .text {
    // border: solid 1px blue;
    margin: 12rem 6rem 0 10rem;
}
.AboutUs .contentDescript .text p{
    padding: .6rem;
    font-size: 2.2rem;
}
.AboutUs .contentDescript .text h1{
    font-size: 6rem;
    margin-bottom: 1rem;
}
.AboutUs .contentDescript .text h3{
    padding: .6rem;
    font-size: 2rem;
    font-weight: 400;
}
.AboutUs .contentDescript .boton{
    margin: 10rem;
}
.AboutUs .contentDescript .boton button {
    height: 6rem;
    width: 12rem;
    border-radius: 6rem;
    border: none;

    font-size: 2rem;

    color: white;

    background-color: orange;
}

.AboutUs .imagePresentation {
    width: 50vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: solid 2px green;
}
.AboutUs .imagePresentation figure{
    // margin-right: 10rem;
    width: 30vw;
    height: 80vh;
    // border: solid 1px blue;
}
.AboutUs .imagePresentation figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1040px) {
    .AboutUs .imagePresentation figure {
        width: 38vw;
        height: 80vh;
    }
}
@media (max-width: 850px) {
    .AboutUs {
        height: 160vh;
        flex-direction: column;
    }
    .AboutUs .contentDescript {
        height: 70vh;
        width: 100vw;
    }
    .AboutUs .contentDescript .text {
        margin: 4rem 2.4rem;
    }
    .AboutUs .contentDescript .boton {
        margin: 0 4rem;
    }
    .AboutUs .imagePresentation {
        width: 100vw;
        margin-top: 4rem;
    }
    .AboutUs .imagePresentation figure{
        margin: 0;
        margin-bottom: 4rem;
        width: 90vw;
    }

}