.OurClients {
    height: 60vh;
    width: 100vw;
    // border: solid 1px greenyellow;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(250, 250, 250);
}
.OurClients .titleClients {
    width: 100vw;
    margin: 4rem 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    // border: solid 1px blue;
}
.OurClients .titleClients h1 {
    font-size: 2.6rem;
}

.OurClients .clientContainer {
    width: 100vw;
    
    // border: solid 1px red;

    display: flex;
    flex-direction: column;
}
.OurClients .clientContainer div{
    // border: solid 1px blue;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    margin: 2.5rem 0;
}
.OurClients .clientContainer figure{
    height: 100px;
    width: 200px;
    margin: 0 8rem;

    display: flex;
    align-items: center;
    justify-content: center;
}
.OurClients .clientContainer img{
    height: 100px;
    width: 100%;
    object-fit: contain;
}

@media (max-width: 500px) {
    .OurClients {
        height: 60vh;
    }
    .OurClients .titleClients h1 {
        font-size: 3.2rem;
        margin: 0 4rem;
        text-align: center;
    }
    .OurClients .clientContainer figure {
        margin: 1rem 2rem;
        height: 70px;
        width: 70px;
    }
    .OurClients .clientContainer img {
        height: 100%;
    }
}
