.QuestionBlock {
    height: auto;
    width: 55vw;

    display: flex;
    flex-direction: column;
    position: relative;

    // border-bottom: solid 1px blue;
    // border: solid 1px green;
}
.QuestionBlock:hover {
    cursor: pointer;
}
.QuestionBlock .question:hover{
    color: grey;
    cursor: pointer;
}
.QuestionBlock:hover .question button {
    color: black;
    cursor: pointer;
}
.QuestionBlock .question {
    padding: 2rem 1.2rem;
    font-size: 2rem;
    
    // background-color: antiquewhite;
    z-index: 1;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px grey;
}
.QuestionBlock .question button {
    border: none;
    background-color: transparent;
    font-size: 3rem;
    margin-right: 2rem;

    transform: rotate(0);
    transition: .2s ease;
}

.QuestionBlock .question .rotate {
    cursor: pointer;
    transform: rotate(90deg);
}
.QuestionBlock .none {
    display: none;
}
.QuestionBlock .answer {
    display: block;
    padding: 2rem 2rem 2rem 2rem;

    border-radius: 16px;
}
.QuestionBlock .answer p {
    font-size: 1.8rem;
}

@media (max-width:500px) {
    .QuestionBlock {
        width: 80vw;
    }
    .QuestionBlock .question h3 {
        font-size: 2.2rem;
    }
}