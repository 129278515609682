.FastTrustData {
    height: 52vh;
    width: 100vw;
    // background-color: #001122;
    // background-color: #101720;
    // background-color: #171717;
    // background-color: #020D19;
    // background-color: #0C0C0C;
    // background-color: #0D1717;
    background-color: #001a56;

    // background-color: #0a2351;

    color: white;
}
.FastTrustData .title {
    font-size: 6vh;
    padding-top: 5vh;
    text-align: center;
}
.FastTrustData .fast-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;
    padding: 0 4rem;
}
.FastTrustData .fast-container .fast-block {
    height: 30vh;
    width: 35vh;

    // border: solid 1px purple;
    padding: 2rem 4rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.FastTrustData .fast-container .fast-block svg {
    height: 5vh;
    width: 5vh;
}
.FastTrustData .fast-container .fast-block h3 {
    font-size: 3.2vh;
    padding-top: 2rem;
}
.FastTrustData .fast-container .fast-block p {
    font-size: 2.2vh;
    padding-top: 1rem;
    text-align: center;
}
@media (max-width: 1040px) {
    .FastTrustData .fast-container .fast-block {
        padding: 0rem 2rem;
    }
    .FastTrustData .fast-container .fast-block h3 {
        font-size: 2.4vh;
    }
    .FastTrustData .fast-container .fast-block p {
        font-size: 1.6vh;
    }
} 
@media (max-width: 500px) {
    .FastTrustData {
        height: auto;
    }
    .FastTrustData .fast-container {
        flex-direction: column;
        align-items: center;
        padding: 4rem 0;
    }
    .FastTrustData .fast-container .fast-block{
        padding: 0 4rem;
    }
}