.Footer {
    color: whitesmoke;
    height: 30vh;
    width: 100vw;
    
    // background-color: #001122;
    // background-color: #101720;
    // background-color: #171717;
    background-color: #020D19;
    // background-color: #0C0C0C;
    // background-color: #0D1717;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.Footer .info {
    width: 90vw;
    height: 24vh;

    border-bottom: 1px solid grey;

    display: flex;
    flex-direction: row;
    align-items: center;
}
.Footer .info .infoBlockContainer {
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Footer .info .infoBlock {
    height: 20vh;
    width: 20vw;
    // border: solid 1px white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
}
// .Footer .info .infoBlock:nth-child(1) {
//     width: 20vw;
//     margin-right: 10vw;
// }
.Footer .info .infoBlock > a {
    height: 75px;
    width: 300px;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    border: solid 2px white;
    border-radius: 6px;
}
.Footer .info .infoBlock > a:hover {
    color: var(--penn-blue);
    border-color: var(--penn-blue);
    cursor: pointer;
}
.Footer .info .infoBlock > a:hover h1{
    color: var(--penn-blue);
}
.Footer .info .infoBlock a h1 {
    color: white;
    font-family: "Orbitron";
    font-size: 44px;
}
.Footer .info .infoBlock .logoSolve {
    height: 50px;
    width: 75px;
    margin: 1rem;
    // object-fit: cover;
}
.Footer .info .infoBlock .socialMedia {
    // border: solid 1px white;
    width: 10vw;
    // margin: 1rem;
}
.Footer .info .infoBlock .socialMedia a {
    color: white;
    width: 90px;
}
.Footer .info .infoBlock .socialMedia a:hover {
    color: var(--penn-blue);
}
.Footer .info .infoBlock svg{
    margin: 1rem .5rem;
    height: 25px;
    width: 25px;
    // object-fit: cover;
}

.Footer .info .infoContact h3{
    font-size: 2.4rem;
}
.Footer .info .infoContact .info_P {
    // Test atributes
    // border: solid 1px white;
    height: 50%;
    
    // margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.Footer .info .infoContact .info_P > p:hover {
    cursor: pointer;
    color: var(--khaki);
}
.Footer .info .infoContact .info_P p{
    font-size: 1.8rem;
}
.Footer .info .infoContact .info_P a p{
    color: white;
}
.Footer .info .infoContact .info_P a:hover p{
    color: var(--khaki);
}
.Footer .info .infoInput {
    margin-bottom: 1rem;
}

// .Footer .info .infoBlock h3 {

//     border: solid 1px white;
// }

.Footer .info .infoInput h3{
    font-size: 2.4rem;
}
.Footer .info .infoInput p{
    margin: .6rem 0 1.2rem 0;
    font-size: 1.4rem;
}
.Footer .info .infoInput .inputSubmit {
    height: 20%;

    display: flex;
    flex-direction: row;
}
.Footer .info .infoInput .inputSubmit input{
    margin-right: 1rem;   
}
.Footer .info .infoInput .inputSubmit button{
    width: 70px;
}
.Footer .copyright {
    margin-top: 1rem;
}
@media (max-width: 1040px) {
    .Footer .info .infoBlock:nth-child(1) {
        width: 32vw;
    }
    .Footer .info .infoBlock .socialMedia {
        width: 12vw;
    }
}
@media (max-width: 500px) {
    .Footer {
        height: 60vh;
    }
    .Footer .info {
        height: 52vh;
        width: 90vw;
        flex-direction: column;
        align-items: center;
    }

    .Footer .info .infoBlockContainer {
        height: 48vh;
        width: 100vw;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        padding-top: 3.6rem;
    }
    .Footer .info .infoBlockContainer .infoBlock {
        width: 45vw;
        padding: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Footer .info .infoBlockContainer .infoBlock:nth-child(1) {
        width: 36vw;
        padding: 0;
    }
    .Footer .info .infoBlock > a {
        width: 82vw;
    }
    .Footer .info .infoBlockContainer .infoBlock .socialMedia {
        width: 50vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Footer .info .infoBlock .socialMedia a {
        width: 40px;
    }
    .Footer .info .infoInput{
        width: 92vw;
    }
}