.MoreRead {
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    // border: solid 1px red;

    padding: 4rem 0;
}
.MoreRead .titleContainer {
    // border: solid 1px green;
    border-bottom: solid 2px black;
    width: 60vw;
    text-align: center;

    margin-bottom: 2rem;
    padding: 1rem;
}
.MoreRead .titleContainer h1 {
    font-size: 4rem;
}
.MoreRead .entries {
    width: 50vw;
    // border: solid 1px blue;

    display: flex;
    justify-content: space-between;
}