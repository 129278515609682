.BlogEntry {
    width: 100vw;
    background-color: var(--antiflash-white);
}
.BlogEntry .presentation {
    height: 70vh;
    width: 100vw;
}
.BlogEntry .presentation figure {
    height: inherit;
    width: inherit;
}
.BlogEntry .presentation figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(50%);
}
.BlogEntry .writing {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.BlogEntry .writing .text-container {
    // border: solid 1px red;
    padding: 4rem 0;
    width: 70vw;

    display: flex;
    flex-direction: column;
}