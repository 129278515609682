.FAQs {
    // height: 100vh;
    width: 100vw;
    background-color: whitesmoke;
}
.FAQs .title figure {
    height: 50vh;
    width: 100vw;

    position: absolute;
}
.FAQs .title figure img{
    height: 100%;
    width: 100%;

    object-fit: cover;

    filter: brightness(30%);
}


.FAQs .title div{
    height: 50vh;
    position: relative;
    
    text-align: center;
    // padding-top: 15vh;
    margin-bottom: 2rem;
    font-size: 3.6rem;

    color: white;

    filter: drop-shadow(0 0 8px black);

    display: flex;
    flex-direction: column;
    justify-content: center;

}

.FAQs .questions {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.FAQs .questions .questionsBlock{
    width: 80vw;
    border-bottom: solid 1px grey;

    display: flex;
    justify-content: space-between;

    padding: 8rem 4rem;
}
.FAQs .questions .questionsBlock h2{
    // border: solid 1px aquamarine;

    font-size: 4rem;
    // margin-left: 16rem;
    padding-top: 1rem;
}
// .FAQs .questions .questionsBlock .block{
//     border: solid 1px green;
// }

@media (max-width: 500px) {
    .FAQs .questions .questionsBlock {
        flex-direction: column;
        align-items: center;
        padding: 0;
        padding-bottom: 8rem;

        border: none;
    }
    .FAQs .questions .questionsBlock h2 {
        font-size: 4.8rem;
        padding: 4rem 0;
    }
    .FAQs .questions .questionsBlock .block {
        width: 80vw;
    }
}