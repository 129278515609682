.Header {
    // @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;1,100&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
    // @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Orbitron:wght@400..900&display=swap');

    height: 10vh;
    width: 100vw;
    background-color: transparent;
    // border: black 2px dotted;

    position: absolute;
    color: white;
    filter: drop-shadow(0px 0px 6px black);
    display: flex;
    align-items: center;
    justify-content: space-between;

    z-index: 3;
    margin: 0;
    position: fixed;

    transition-property: background-color;
    transition-duration: .2s;
}
.Header:hover {
    background-color: rgba(11, 18, 21, 0.299);
}

.Header .logoContainer {
    margin: 1rem;
}
.Header .logoContainer a {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Header .logoContainer a h1 {
    color: white;
    font-size: 4.8rem;
    font-family: "Orbitron";
    // font-family: monospace;
    font-weight: 500;
    // font-style: italic;
    // padding-bottom: .6rem;

    transition: color 0.2s ease;
    z-index: 2;
}
.Header .logoContainer a:hover h1 {
    color: var(--penn-blue);

    // filter: drop-shadow(0 0 8px white);
}
.Header .logoSolve{
    height: 70px;
    width: 70px;
    padding: 0 1rem 0 2rem;

    z-index: 2;
}
.Header .menu-responsive {
    display: none;
}
.Header .display-laptop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // border: solid 1px green;

    height: 100%;

    padding: 0 2rem;
}
.Header .display-laptop ul {
    font-size: 1.8rem;
    margin: .6rem;
    // border: solid 1px blue;
    font-weight: bold;
}
.Header .display-laptop a {
    color: white;
}
.Header .display-laptop a:hover ul{
    // color: rgba(0, 0, 0, 0.500);
    filter: drop-shadow(0 0 4px white);
}
.Header .display-laptop a ul{
    color: white;
    font-weight: 400;

    padding: 1rem;
    border: solid 1px transparent;
}
.Header .display-laptop button {
    background-color: transparent;
    color: white;
    border: solid 1px white;
    border-radius: 8px;

    height: 40px;
    width: 40px;

    font-size: 1.8rem;
}
.Header .display-laptop button:hover {
    cursor: pointer;
    color: var(--khaki);
    border-color: var(--khaki);
}
@media (max-width: 1040px) {
    .Header .display-laptop a ul {
        font-size: 1.6rem;
        margin: 0;
    }
}
@media(max-width: 500px) {
    .Header .display-laptop {
        display: none;
    }

    .Header .logoContainer a h1 {
        display: none;
    }

    // Diseño de los palitos
    .Header .menu-responsive{
        margin-top: 1rem;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        width: 3rem;
        height: 3rem;
        border: 0;
        background: transparent;
        gap: .65rem;
        z-index: 5;
        // border: solid 1px white;
    }
    .Header .menu-responsive:hover {
        cursor: pointer;
    }
    .Header .menu-responsive > div {
        background: white;
        height: 2px;
        width: 100%;
        border-radius: 5px;
        transition: all .5s;
        transform-origin: left;
    }
    .Header .menu-responsive:hover div {
        background: var(--khaki);
    }
    .Header .menu-responsive:hover div:first-child {
        transform: rotate(35deg);
    }
    .Header .menu-responsive:hover div:nth-child(2) {
        opacity: 0
    }
    .Header .menu-responsive:hover div:last-child {
        transform: rotate(-35deg);
    }
    // hasta aquí la animación de los palitos


    .Header .display-responsive {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        top: 0;

        width: 100vw;
        position: absolute;

        padding-top: 100px;

        background-color: rgba(11, 18, 21, 0.799);

    }
    .Header .display-responsive a {
        width: 90vw;
        color: white;
        text-align: center;
        font-size: 2.8rem;
        
        margin: 2rem;

        transition: color 0.2s ease;
    }
    .Header .display-responsive a:hover{
        color: var(--khaki);
    }
    .Header .display-responsive button {
        background-color: transparent;
        color: white;
        border: solid 1px white;
        border-radius: 8px;
    
        height: 40px;
        width: 40px;
    
        font-size: 1.8rem;

        margin-top: 1.6rem;

        transition: color 0.2s ease;
        transition: border-color 0.2s ease;
    }
    .Header .display-responsive button:hover {
        cursor: pointer;
        color: var(--khaki);
        border-color: var(--khaki);
    }
}