.OurClientsConstruction {
    height: 70vh;
    width: 100vw;
    // border: solid 1px greenyellow;

    
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
}

.OurClientsConstruction .clientContainer {
    // font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: #020D17;

    width: 30vw;
    height: 70vh;
    
    // border: solid 1px red;

    display: flex;
    flex-direction: column;
    padding-left: 10vw;

    color: white;
    // font-style: italic;
}

.OurClientsConstruction .clientContainer .userComment {
    padding-top: 15vh;
    height: 30vh;
    width: 30vw;

    // border: solid 1px blue;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.OurClientsConstruction .clientContainer .userComment h1{
    margin-bottom: 2rem;
    font-size: 2.8rem;
    padding-right: 2rem;


    font-family: 'Courier New', Courier, monospace;
}
.OurClientsConstruction .clientContainer .userComment h3 {
    padding-right: 2rem;

    font-size: 2rem;
    font-family: 'Courier New', Courier, monospace;

}

.OurClientsConstruction .clientContainer .proyectPanel {
    width: 400px;
    // border: solid 1px white;

    margin-top: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.OurClientsConstruction .clientContainer .proyectPanel .navButton {
    font-size: 4rem;
    // border: solid 1px hotpink;

    display: flex;
    justify-content: flex-end;
}
.OurClientsConstruction .clientContainer .proyectPanel button {
    height: 40px;
    width: 220px;
    border: solid 1px white;
    border-radius: 8px;

    font-family: 'Courier New', Courier, monospace;

    color: white;
    background-color: transparent;
}
.OurClientsConstruction .clientContainer .proyectPanel button:hover {
    cursor: pointer;
    color: var(--khaki);
    border-color: var(--khaki);
}

.OurClientsConstruction .clientContainer .proyectPanel .navButton svg {
    height: 40px;
    width: 40px;
    // border: solid 1px white;
    color: gray;
    margin: 0 0.8rem;
}
.OurClientsConstruction .clientContainer .proyectPanel .navButton svg:hover{
    cursor: pointer;
    color: white;
}
@media  (max-width:1040px) {
    .OurClientsConstruction .clientContainer {
        width: 37vw;
        padding-left: 6vw;
        display: flex;
        justify-content: space-evenly;
    }
    .OurClientsConstruction .clientContainer .userComment {
        padding-top: 0vh;
    }
    .OurClientsConstruction .clientContainer .proyectPanel {
        width: 90%;
    }
    .OurClientsConstruction .clientContainer .proyectPanel button {
        height: 48px;
        width: 160px;
    }
}
@media (max-width: 500px) {
    .OurClientsConstruction {
        height: 120vh;
        flex-direction: column;
    }
    .OurClientsConstruction .clientContainer {
        height: 50vh;
        width: 100vw;
    }

    .OurClientsConstruction .clientContainer .userComment {
        padding-top: 4rem;
        width: 80vw;
    }
    .OurClientsConstruction .clientContainer .proyectPanel {
        margin-top: 2rem;
        justify-content: flex-start;
    }
    .OurClientsConstruction .clientContainer .proyectPanel .navButton {
        margin-left: 2.8rem;
    }
    .OurClientsConstruction .clientContainer .proyectPanel .navButton svg {
        margin: 0;
    }
}