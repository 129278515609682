.Services {
    height: 100vh;
    width: 100vw;
    // border: solid 1px blue;

    box-sizing: border-box;
}
.Services .servicesContainer {
    margin: 4rem;
    // border:  solid 1px red;

    display: flex;
    justify-content: space-between;
}

.Services .servicesContainer .serviceBar{
    // border: solid 1px greenyellow;
    margin: 4rem 8rem;
    width: 40vw;
}
.Services .servicesContainer .serviceBar h2 {
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 4rem;
    margin-left: 4rem;
    width: 50%;
}
.Services .servicesContainer .serviceBar .serviceBox li {
    list-style: none;
    margin: 4rem 0 4rem 2rem;
    display: flex;
    align-items: center;

    font-size: 1.8rem;
}
.Services .servicesContainer .serviceBar .serviceBox li .icon {
    height: 40px;
    width: 40px;
    margin-right: 1rem;
}
.Services .servicesContainer .serviceBar .serviceBox li .icon svg {
    height: inherit;
    width: inherit;
}
.Services .servicesContainer .serviceBar .serviceBox li p{
    padding: 0 2rem;
}

.Services .servicesContainer .imageContainer {
    padding: 4rem 14rem 4rem 0;
    height: 80vh;
    // border: solid 1px aquamarine;
}
.Services .servicesContainer .imageContainer img{
    height: 100%;
    object-fit: cover;
    filter: drop-shadow(0 0 8px grey);
}
@media (max-width: 1040px) {
    .Services .servicesContainer .serviceBar h2 {
        width: 70%;
    }
    .Services .servicesContainer .serviceBar {
        margin: 4rem 0rem;
        width: 40vw;
    }
    .Services .servicesContainer .imageContainer {
        padding: 4rem 0rem 0rem 0;
    }
    .Services .servicesContainer .serviceBar .serviceBox li {
        margin: 5rem 0 5rem 2rem;
    }
    .Services .servicesContainer .serviceBar .serviceBox li {
        font-size: 1.6rem;
    }
}
@media (max-width:500px) {
    .Services {
        height: 152vh;
    }
    .Services .servicesContainer {
        flex-direction: column;
    }
    .Services .servicesContainer .serviceBar {
        margin: 0;
        width: 85vw;
    }
    .Services .servicesContainer .serviceBar h2 {
        width: 70vw;
        margin: 0;
        margin-bottom: 4rem;
        text-align: center;
    }
    .Services .servicesContainer .serviceBar li {
        margin: 2.8rem 0;
    }
    .Services .servicesContainer .imageContainer {
        height: 62vh;
        width: 80vw;
        padding: 4rem 0;
    }
    .Services .servicesContainer figure img {
        width: 100%;
        height: 90%;
    }
}