.Pros {
    height: 50vh;
    width: 100vw;
    // border: solid 1px red;
    background-color: #0f0f0f;

    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.Pros .title h1 {
    font-size: 4rem;
}

.Pros .advantageBox {
    // border: solid 1px white;
    width: 90vw;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.Pros .advantageBox .box {
    height: 20vh;
    width: 300px;
    text-align: center;
    // border: solid 1px red;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    // justify-content: space-between;
}

.Pros .advantageBox .box svg {
    height: 50px;
    width: 50px;
    margin-bottom: 1rem;
}
.Pros .advantageBox .box h3 {
    font-size: 2.6rem;
    margin-bottom: 1rem;
}
.Pros .advantageBox .box p {
    text-align: center;
    font-size: 1.4rem;
}
@media (max-width: 1040px) {
    .Pros .advantageBox .box {
        width: 270px;
    }
    .Pros .advantageBox .box h3 {
        font-size: 2.2rem;
    }
}
@media (max-width: 500px) {
    .Pros {
        height: 100vh;
    }
    .Pros .advantageBox {
        flex-direction: column;
        height: 80vh;
    }
}