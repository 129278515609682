* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    box-sizing: border-box;
    overflow-x: hidden;
}

:root {
    /* CSS HEX */
    --penn-blue: #001a56ff;
    --davys-gray: #635d5cff;
    --khaki: #ceb992ff;
    --pakistan-green: #0a3200ff;
    --antiflash-white: #eeeeeeff;
    
    /* CSS HSL */
    --penn-blue: hsla(222, 100%, 17%, 1);
    --davys-gray: hsla(9, 4%, 37%, 1);
    --khaki: hsla(39, 38%, 69%, 1);
    --pakistan-green: hsla(108, 100%, 10%, 1);
    --antiflash-white: hsla(0, 0%, 93%, 1);
    
    /* SCSS HEX */
    $penn-blue: #001a56ff;
    $davys-gray: #635d5cff;
    $khaki: #ceb992ff;
    $pakistan-green: #0a3200ff;
    $antiflash-white: #eeeeeeff;
    
    /* SCSS HSL */
    $penn-blue: hsla(222, 100%, 17%, 1);
    $davys-gray: hsla(9, 4%, 37%, 1);
    $khaki: hsla(39, 38%, 69%, 1);
    $pakistan-green: hsla(108, 100%, 10%, 1);
    $antiflash-white: hsla(0, 0%, 93%, 1);
    
    /* SCSS RGB */
    $penn-blue: rgba(0, 26, 86, 1);
    $davys-gray: rgba(99, 93, 92, 1);
    $khaki: rgba(206, 185, 146, 1);
    $pakistan-green: rgba(10, 50, 0, 1);
    $antiflash-white: rgba(238, 238, 238, 1);
    
    /* SCSS Gradient */
    $gradient-top: linear-gradient(0deg, #001a56ff, #635d5cff, #ceb992ff, #0a3200ff, #eeeeeeff);
    $gradient-right: linear-gradient(90deg, #001a56ff, #635d5cff, #ceb992ff, #0a3200ff, #eeeeeeff);
    $gradient-bottom: linear-gradient(180deg, #001a56ff, #635d5cff, #ceb992ff, #0a3200ff, #eeeeeeff);
    $gradient-left: linear-gradient(270deg, #001a56ff, #635d5cff, #ceb992ff, #0a3200ff, #eeeeeeff);
    $gradient-top-right: linear-gradient(45deg, #001a56ff, #635d5cff, #ceb992ff, #0a3200ff, #eeeeeeff);
    $gradient-bottom-right: linear-gradient(135deg, #001a56ff, #635d5cff, #ceb992ff, #0a3200ff, #eeeeeeff);
    $gradient-top-left: linear-gradient(225deg, #001a56ff, #635d5cff, #ceb992ff, #0a3200ff, #eeeeeeff);
    $gradient-bottom-left: linear-gradient(315deg, #001a56ff, #635d5cff, #ceb992ff, #0a3200ff, #eeeeeeff);
    $gradient-radial: radial-gradient(#001a56ff, #635d5cff, #ceb992ff, #0a3200ff, #eeeeeeff);

}

@media(max-width: 500px) {
    #root {
        overflow-x: hidden;
    }
}