.image {
    height: 90vh;
    width: 40vw;
    // border: solid 1px red;
}
.image figure {
    height: inherit;
    width: inherit;
}
.image figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.description {
    height: 90vh;
    width: 50vw;

    // border: solid 1px blue;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.description .descContainer {

    background-color: var(--antiflash-white);
    border-radius: 8px;
    margin: 0 4rem;
    padding: 4rem;
    width: 40vw;
    height: 38vh;
}
.description .descContainer h3 {
    font-size: 2rem;
    display: flex;
    align-items: center;
}
.description .descContainer h1 {
    font-size: 4rem;
    margin-bottom: 3rem;
}
.description .descContainer p {
    font-size: 2rem;
    margin-top: 1rem;
}
.description .ventajas {
    height: 22vh;
    width: 40vw;

    margin: 0 4rem;
    padding: 4rem;

    border-radius: 8px;
    background-color: var(--penn-blue);
    color: var(--antiflash-white);
}
.description .ventajas h3{
    font-size: 2.8rem;
    margin-bottom: 2rem;
}
.description .ventajas p {
    font-size: 2rem;
    padding: 0.6rem 0;
}
.description .ventajas p svg{
    padding-right: 1rem;
}

@media (max-width: 500px) {
    .image {
        height: 60vh;
        width: 90vw;

        padding-top: 4rem;
    }
    .description {
        width: 90vw;
        height: 115vh;
        align-items: center;
        // border: solid 1px red;
    }
    .description .descContainer {
        width: 68vw;
        height: auto;

        margin: 0;
        padding: 4rem;
    }
    .description .ventajas {
        margin: 2rem;
        height: 33vh;
        width: 68vw;
    }
}