.CategoryBlocks {
    height: 60vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.CategoryBlocks .category {
    height: inherit;
    width: 25vw;
    // border: var(--penn-blue) solid 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.CategoryBlocks .category:hover {
    cursor: pointer;
    // border: solid 1px var(--antiflash-white);
    z-index: 1;
}

.CategoryBlocks .category .img-wrapper{
    height: 60vh;
    width: inherit;
    position: absolute;
    overflow: hidden;
}
.CategoryBlocks .category .img-wrapper .hover-zoom{
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: ease .4s transform;
}

.CategoryBlocks .category .img-wrapper .hover-zoom:hover {
    transform: scale(1.25);
}

.CategoryBlocks .category p{
    position: relative;
    color: white;
    font-size: 3rem;

    filter: drop-shadow(0 0 8px black);
}

@media (max-width: 500px) {
    .CategoryBlocks {
        flex-wrap: wrap;
        height: 400vw;
    }
    .CategoryBlocks .category {
        width: 100vw;
        height: 100vw;
        // border-right: solid 4px black;
        // border-left: solid 4px black;
        // border-bottom: solid 8px black;
    }
    .CategoryBlocks .category .img-wrapper {
        height: inherit;
    }
    
    // .CategoryBlocks .category:nth-child(1) {
    //     border-top: solid 8px black;
    // }
}