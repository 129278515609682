.InfoIntro {
    height: 10vh;
    width: 100vw;

    // border: solid 1px blue;

    display: flex;
    align-items: center;
    justify-content: center;
}
.InfoIntro .masterContainer {
    margin-top: 2px;
    // border-bottom: solid 2px grey;
    height: 100%;
    width: 90vw;

    display: flex;
    align-items: center;
    justify-content: space-between;
}


.InfoIntro .masterContainer section {
    margin: 0 8rem;
}

.InfoIntro .masterContainer .textSection {
    height: 100%;
    // border: solid 1px red;

    display: flex;
    align-items: center;
    justify-content: center;
}
.InfoIntro .masterContainer .textSection h3 {
    font-size: 2rem;
}

.InfoIntro .masterContainer .boxSection {
    height: 100%;
    width: 200px;
    // border: solid 1px red;


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.InfoIntro .masterContainer .boxSection .boxContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.InfoIntro .masterContainer .boxSection .boxContainer .box {
    height: 50px;
    width: 50px;
    // border: solid 1px purple;

    display: flex;
    align-items: center;
    justify-content: center;
}
.InfoIntro .masterContainer .boxSection .boxContainer .box svg{
    color: var(--penn-blue);
    height: 30px;
    width: 30px;
}
.InfoIntro .masterContainer .boxSection .boxContainer .box svg:hover {
    cursor: pointer;
    color: var(--davys-gray)
}

@media (max-width: 500px) {
    .InfoIntro .masterContainer {
        justify-content: space-around;
    }
    // .InfoIntro .masterContainer .boxSection {
    //     display: none;
    // }
    .InfoIntro .masterContainer section {
        margin: 0;
    }

    .InfoIntro .masterContainer .boxSection {
        width: 25vw;
    }
    .InfoIntro .masterContainer .boxSection .boxContainer {
        justify-content: center;
    }
}