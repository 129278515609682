.Expand {
    height: 110vh;
    width: 100vw;
    // border: solid 1px black;
    // background-color: var(--antiflash-white);

    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@media (max-width:500px) {
    .Expand {
        height: 200vh;
        // border: solid 1px blue;
        flex-direction: column;
    }
}