.IntroPicture {
    height: 100vh;
}
.IntroPicture figure{
    position: absolute;
    z-index: -1;
    height: 100vh;
    width: 100vw;
}
.IntroPicture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
}
.IntroPicture .texto {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    color: white;
    top: 20rem;
    position: relative;
    filter: drop-shadow(0 0 16px black);
}
.IntroPicture .texto h1 {
    font-size: 8rem;
    // filter: drop-shadow(0 0 8px black);
}
.IntroPicture .texto p {
    font-size: 2.6rem;
    margin-top: 2rem;
}
@media (max-width: 1040px) {
    .IntroPicture .texto h1 {
        font-size: 5rem;
    }
}
@media (max-width:500px) {
    .IntroPicture .texto h1 {
        font-size: 4rem;
        text-align: center;
    }
    .IntroPicture .texto p {
        font-size: 2rem;
        text-align: center;
        padding: 0 1rem;
    }
}