.ProjectsPage {
    width: 100vw;
    height: 100vh;

    // border: solid 1px green;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.ProjectsPage .imagePage {
    // margin-top: 6rem;
    // margin-bottom: 6rem;
    height: 100vh;
    width: 100vw;
    
    // border-radius: 2rem;

    position: relative;
}
.ProjectsPage .imagePage img{
    width: 100%;
    height: 100%;

    object-fit: cover;

    border-radius: inherit;
}

.ProjectsPage .boton {
    height: 100px;
    width: 50px;

    position: absolute;
    background-color: transparent;
    border: none;

    color: white;

    transition: background-color .2s ease;
}
.ProjectsPage .boton {
    z-index: 1;
}
.ProjectsPage .boton svg {
    height: 20px;
    width: 20px;
}
.ProjectsPage .boton:hover {
    background-color: rgba(69, 69, 69, 0.5);
    border-radius: 4px;
    // border: solid 1px black;
    cursor: pointer;
}
.ProjectsPage .botonAnterior {
    left: 0;
    top: 40vh;
}
.ProjectsPage .botonSiguiente {
    right: 0;
    top: 40vh;
}
.ProjectsPage .component-container {
    width: 100vw;
    height: inherit;
    position: absolute;

    display: flex;
    justify-content: center;
}
@media (max-width: 500px) {
    .ProjectsPage {
        height: 100vh;
        // padding-bottom: 4rem;
    }
    .ProjectsPage .imagePage {
        height: 100%;
    }
    .ProjectsPage .imagePage img{
        width: 100%;
        height: 100%;

        object-fit: cover;
    }

    .ProjectsPage .component-container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}