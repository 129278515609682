.BlogDisplayIntro {
    height: 60vh;
    width: 100%;
    // border: solid 1px purple;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.BlogDisplayIntro .listView {
    // margin-top: 4rem;
    padding: 2rem 0;
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: grey 2px solid;
}

.BlogDisplayIntro .listView .image {
    height: 90%;
    width: 38vw;
    // border: solid 1px blue;
}
.BlogDisplayIntro .listView .image figure {
    height: 100%;
    width: inherit;
}
.BlogDisplayIntro .listView .image figure:hover {
    cursor: pointer;
}
.BlogDisplayIntro .listView .image figure img{
    height: inherit;
    width: inherit;
    object-fit: cover;

    border-radius: 0px;
}

.BlogDisplayIntro .listView .info {
    height: 45vh;
    width: 45vw;

    // border: solid 1px red;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // padding-bottom: 4rem;
}
.BlogDisplayIntro .listView .info .texto{
    width: 45vw;

    margin-top: 2rem;
    padding-left: 2rem;
}
.BlogDisplayIntro .listView .info .texto h2{
    font-size: 4rem;
    color: black;
}
.BlogDisplayIntro .listView .info .texto h2:hover {
    cursor: pointer;
}
.BlogDisplayIntro .listView .info .texto h3{
    font-size: 2.2rem;
    font-weight: 400;

    color: var(--davys-gray);
    margin: 2rem 0;
}
.BlogDisplayIntro .listView .info p{
    // margin: 4rem 0;
    padding-top: 2rem;
    padding-left: 2rem;
    font-size: 1.6rem;
    border-top: solid 1px var(--davys-gray);

    color: var(--davys-gray);
}

// Here is the grid view
.BlogDisplayGrid {
    height: 65vh;
    width: 30%;
    // border: solid 1px purple;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.BlogDisplayGrid .listView {
    // margin-top: 4rem;
    padding: 2rem 0;
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: grey 2px solid;
}
.BlogDisplayGrid .gridView{
    flex-direction: column;
    align-items: center;
}
.BlogDisplayGrid .gridView .image {
    height: 33vh;
    width: 25vw;
}
.BlogDisplayGrid .gridView .image a {
    height: inherit;
    width: inherit;
}
.BlogDisplayGrid .gridView .image a figure img {
    height: inherit;
    width: inherit;
    object-fit: cover;
    border-radius: 16px;
}
.BlogDisplayGrid .gridView .info {
    height: 20vh;
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.BlogDisplayGrid .gridView .info .texto {
    width: inherit;
    margin-top: 2rem;
}
.BlogDisplayGrid .gridView .info .texto h2 {
    font-size: 2rem;
    color: black;
}
.BlogDisplayGrid .gridView .info .texto h3 {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--davys-gray);
    margin: 1rem 0;
}
.BlogDisplayGrid .gridView .info p {
    padding-top: 2rem;
    /* padding-left: 2rem; */
    font-size: 1.4rem;
    border-top: solid 1px var(--davys-gray);
    color: var(--davys-gray);
}

@media (max-width:1040px) {
    .BlogDisplayIntro .listView .info {
        height: 50vh;
        justify-content: space-evenly;
    }
    .BlogDisplayIntro .listView .info .texto h3 {
        font-size: 2rem;
    }
}

@media (max-width:500px) {
    .BlogDisplayIntro .listView {
        flex-direction: column;
    }
    .BlogDisplayIntro .listView .image {
        padding: 0;
        position: absolute;
        height: 50vh;
        width: 85vw;
        filter: brightness(40%);
    }
    .BlogDisplayIntro .listView .info {
        width: 85vw;
        z-index: 2;
    }
    .BlogDisplayIntro .listView .info .texto {
        width: 75vw;
        padding: 2rem;
    }
    .BlogDisplayIntro .listView .info .texto h2 {
        color: var(--antiflash-white);
        font-size: 3.2rem;
    }
    .BlogDisplayIntro .listView .info .texto h3 {
        color: var(--antiflash-white);
        font-size: 1.6rem;
    }
    .BlogDisplayIntro .listView .info p {
        display: none;
    }

    .BlogDisplayGrid {
        width: 80vw;
    }
    .BlogDisplayGrid .gridView .image {
        width: 80vw;
        padding: 0;
    }
    .BlogDisplayGrid .gridView .info {
        width: 80vw;
    }
}