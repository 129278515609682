.PresentationConst {
    width: 100vw;
    height: 100vh;
    display: flex;
}
.PresentationConst .constDescription{
    background-color: rgba($color: #000000, $alpha: .7);
    width: 40vw;
    padding-right: 5vw;
    height: 100vh;
    position: relative;
    z-index: 2;
    color: white;

    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
}
.PresentationConst .constDescription .textContainer {
    width: 100%;
    height: 80%;
    // border: solid 1px red;
    padding: 4rem 8rem;
}
.PresentationConst .constDescription .textContainer h1{
    font-size: 5rem;
    margin: 2rem 0;
}
.PresentationConst .constDescription .textContainer p{
    font-size: 2rem;
    margin-top: 2rem;
}
.PresentationConst .constDescription .textContainer .hablemos{
    margin-top: 6rem;
    height: 60px;
    width: 150px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1.6rem;
}
.PresentationConst .constDescription .hablemos:hover {
    cursor: pointer;
    background-color: white;
    color: grey;
    filter: drop-shadow(0 0 8px grey);
}
.PresentationConst .divider{
    background-color: rgba($color: #000000, $alpha: .7);

    height: 100vh;
    width: 10vw;
    clip-path: polygon(0% 100%,0% -1%,100% -1%);
    position: relative;
    // right: 10vw;
}
.PresentationConst .imageContainer{
    background-color: rgb(34, 34, 106);
    width: 100vw;
    height: 100vh;
    position: absolute;
}
.PresentationConst .imageContainer figure {
    width: 100vw;
    height: 100vh;
}
.PresentationConst .imageContainer figure img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media (max-width: 1040px) {
    .PresentationConst .divider {
        clip-path: polygon(-1% 100%, -1% -1%, 100% 0%);
    }
    .PresentationConst .constDescription {
        width: 50vw;
    }
}
@media(max-width: 500px) {
    .PresentationConst {
        height: 125vh;
    }
    .PresentationConst .constDescription {
        width: 100vw;
        height: 125vh;
    }
    .PresentationConst .constDescription .textContainer {
        padding: 0 4rem;
    }
    .PresentationConst .constDescription .textContainer h1 {
        margin: 0;
    }
    .PresentationConst .constDescription .textContainer p {
        width: 85vw;
    }


    .PresentationConst .divider {
        display: none;
    }

    .PresentationConst .imageContainer {
        height: 125vh;
    }
    .PresentationConst .imageContainer figure{
        height: inherit;
    }

}