.LetsConect {
    height: 60vh;
    width: 100vw;
    // border: solid 1px hotpink;

    display: flex;
    align-items: center;
    justify-content: center;
}
.LetsConect .container {
    width: 90vw;
    height: 55vh;
    // border: solid 1px blue;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.LetsConect .container .text{
    width: 35vw;
    height: 100%;
    
    // border: solid 1px red;
    margin: 4rem;
}
.LetsConect .container .text h3{
    margin-top: 4rem;
    margin-left: 2rem;
    font-size: 1.6rem;

    display: flex;
    align-items: center;
}

.LetsConect .container .text h3 svg {
    width: 25px;
    height: 25px;
}
.LetsConect .container .text h1{
    // margin-top: 1rem;
    font-size: 4rem;
}
.LetsConect .container .text p{
    margin-top: 3.6rem;
    font-size: 1.4rem;
}


.LetsConect .container .form{
    width: 42vw;
    height: 100%;
    
    // border: solid 1px red;
}
.LetsConect .container .form h1{
    margin-top: 6.5rem;
    margin-bottom: 2.8rem;
    font-size: 3.2rem;
}
.LetsConect .container .form form{
    width: 40vw;
    display: flex;

    flex-direction: column;

}
.LetsConect .container .form form input{
    font-size: 1.6rem;
    width: 30vw;
    height: 4rem;
    
    border: none;
    border-bottom: solid 2px grey;
    // border: solid 2px grey;
    
    margin-bottom: 2rem;
}
.LetsConect .container .form form button {
    margin-top: 2rem;
    
    height: 4.4rem;
    width: 12rem;
    font-size: 1.6rem;

    background-color: rgb(173, 173, 173);
    border: none;
    border-radius: 4px;
}
.LetsConect .container .form form button:hover {
    border: solid 2px black;
    color: white;
    background-color: grey;

    font-weight: bold;
    cursor: pointer;
}

@media (max-width:500px) {
    .LetsConect {
        padding-top: 4rem;
        height: 110vh;
        align-items: flex-start;
    }
    .LetsConect .container {
        flex-direction: column;
        height: 90vh;
    }
    .LetsConect .container .text{
        width: 80vw;
        margin: 0;
    }
    .LetsConect .container .form{
        width: 80vw;
        margin: 0;
        
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .LetsConect .container .form form {
        width: 100%;
        
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .LetsConect .container .form form input {
        width: 70vw;
    }
    .LetsConect .container .text p {
        font-size: 1.8rem;
    }
}