.AboutConstruction {
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;
}

.AboutConstruction .container {
    height: 100vh;
    width: 100vw;
    // border: solid 1px red;

    // display: flex;
    // align-items: center;
    // justify-content: space-between;
}

.AboutConstruction .container .imagenCont {
    height: 100vh;
    width: 100vw;
    // border: solid 1px blue;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}
.AboutConstruction .container .imagenCont img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    // filter: drop-shadow(0 0 8px grey);
}
.AboutConstruction .container .relativeContainer .polygon {
    height: 100vh;
    width: 10vw;
    clip-path: polygon(0% 0%,100% 0%,0% 100%);
    // border: solid 1px white;
    padding-right: 10vw;

    background-color: rgba(0, 0, 0, 0.75);
}
.AboutConstruction .container .relativeContainer {
    // left:20vw;
    position: relative;
    // background-color: rgba(0, 0, 0, 0.5);

    height: 100vh;
    width: 80vw;

    display: flex;
    // flex-direction: column;
    justify-content: center;
}
.AboutConstruction .container .text {
    background-color: rgba(0, 0, 0, 0.75);

    color: white;
    height: 100vh;
    width: 60vw;
    // padding-right: 10vw;
    padding-left: 10rem;
    // border: solid 1px orangered;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.AboutConstruction .container .text .textContent {
    margin-top: 2.6rem;
    filter: drop-shadow(0 0 8px black);
}

.AboutConstruction .container .text .textContent h3 {
    font-size: 1.6rem;
}
.AboutConstruction .container .text .textContent h1 {
    font-size: 4.4rem;
    margin-bottom: 2rem;
}
.AboutConstruction .container .text .textContent p {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.AboutConstruction .container .text .infoBlocks {
    // border: solid 1px blue;
    margin-bottom: 2rem;
    margin-right: 2rem;

    height: 150px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.AboutConstruction .container .text .infoBlocks .block {
    font-size: 2rem;
}
.AboutConstruction .container .text .infoBlocks .block h3 {
    font-size: 3.2rem;
}
.AboutConstruction .container .text .infoBlocks .block p {
    font-size: 2rem;
}

@media (max-width: 500px) {
    .AboutConstruction .container .relativeContainer {
        width: 100vw;
    }
    .AboutConstruction .container .text {
        width: 100vw;
        padding: 0 4rem;
    }
    .AboutConstruction .container .text .infoBlocks {
        margin: 0;
        width: 90vw;
    }
    .AboutConstruction .container .text .infoBlocks .block {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 116px;
        // border: solid 1px white;
        padding: 0 12px;
    }
    .AboutConstruction .container .relativeContainer .polygon {
        display: none;
    }
}