.Description {
    position: absolute;
    color: var(--antiflash-white);
    top: 15.6vh;
    left: 5.8vw;

    filter: drop-shadow(0 0 8px black);

    // border: solid 1px blue;
}
.Description .categoryContainer {
    font-size: 2rem;
    display: flex;
}
.Description .categoryContainer p:nth-child(2) {
    margin: 0 0.8rem;
}
.Description .categoryContainer .category-true{
    color: white;
}
.Description .categoryContainer .category-false{
    color: rgb(176, 175, 175);
    // filter: invert(2);
    // filter: none;
}
.Description .categoryContainer .category-false:hover {
    color: var(--penn-blue);
    cursor: pointer;
}
.Description h1 {
    font-size: 6rem;
}

.Description .reviewContainer {
    margin: 2vh 0;
    height: 20vh;
    width: 20vw;
    // background-color: var(--antiflash-white);
    background-color: rgba(27, 77, 193, 0.5);
    box-shadow: 0px 0px 10px 3px var(--penn-blue);

    border-radius: 8px;
}
.Description .reviewContainer h3 {
    padding: 1.6rem 0 0 2rem;
    color: white;
    font-size: 2rem;
} 
.Description .reviewContainer p {
    color: white;
    padding: 1.2rem 2rem 0 2rem;

    font-style: italic;
    font-size: 1.8rem;
}

@media (max-width: 500px) {
    .Description {
        position: absolute;
        top: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
    }
    .Description .categoryContainer {
        font-size: 1.6rem;
        display: flex;
    }
    .Description h1 {
        font-size: 3.6rem;
        text-align: center;
    }
    .Description .reviewContainer {
        width: 70vw;
    }
}
