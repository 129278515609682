.ContactPage {
    height: 100vh;
    width: 100vw;
}
.ContactPage .imageContainer{
    height: 100vh;
    width: 100vw;
    position: absolute;
}
.ContactPage .imageContainer figure{
    height: 100vh;
    width: 100vw;
}
.ContactPage .imageContainer figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ContactPage .divider {
    height: 100vh;
    width: 10vw;
    clip-path: polygon(0% 0%,100% 100%,100% 0%);
    background-color: rgba(0, 0, 0, 0.5);
    
    position: absolute;
    left: 25vw;
    // border: solid 1px red;
}

.ContactPage .contactBox {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 65vw;
    position: relative;
    left: 35vw;
    padding: 0 15vw;
    color: white;
}
.ContactPage .contactBox h1 {
    position: inherit;
    top: 13vh;
    font-size: 5rem;
    filter: drop-shadow(0 0 8px black);
}
.ContactPage .contactBox form{
    // border: solid 1px red;
    position: absolute;
    top: 28vh;
    width: 30vw;
    height: 76vh;
}
.ContactPage .contactBox form .inputHorizontal {
    display: flex;
    justify-content: space-between;
}
.ContactPage .contactBox form .inputContainer p{
    font-size: 2rem;
    filter: drop-shadow(0 0 8px black);
}
.ContactPage .contactBox form .inputContainer input{
    height: 3rem;
    width: 18rem;
    border-radius: 4px;
    border: transparent 2px solid;
    padding: 0 1rem;
    margin: 1rem 0;
    filter: drop-shadow(0 0 8px black);
}

.ContactPage .contactBox form .inputMessage textarea{
    padding: 1rem;
    width: 35rem;
    height: 8rem;
    border-radius: 4px;
    margin-top: 1rem;

    filter: drop-shadow(0 0 8px black);
}

.ContactPage .contactBox form button {
    margin: 4rem 0;
    height: 5rem;
    width: 10rem;
    border-radius: 1rem;
    border: solid 1px black;
}

.ContactPage .contactBox form button:hover {
    cursor: pointer;
    background-color: white;
    filter: drop-shadow(0 0 8px black);
    font-weight: bold;
}

@media(max-width:500px) {
    .ContactPage {
        height: 110vh;
    }
    .ContactPage .imageContainer {
        height: inherit;
    }
    .ContactPage .imageContainer figure {
        height: inherit;
    }
    .ContactPage .divider {
        display: none;
    }
    .ContactPage .contactBox {
        left: 0;
        padding: 0;
        width: 100vw;
        height: inherit;
    }
    .ContactPage .contactBox h1{
        text-align: center;
    }
    .ContactPage .contactBox form {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ContactPage .contactBox form .inputHorizontal {
        flex-direction: column;
        align-items: center;
    }
    .ContactPage .contactBox form .inputMessage textarea {
        width: 68vw;
        height: 10vh;
    }
}