.BlogContainer {
    // Este height es temporal
    // height: 100vh;
    width: 100vw;

    // border: solid 1px red;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.BlogContainer > div {
    border-top: solid 2px grey;
}

.BlogContainer .listContainer {
    width: 90vw;
}
.BlogContainer .listContainer .BlogDisplayIntro:nth-child(1) .listView {
    border: none;
    width: 90vw;
}

.BlogContainer .gridContainer {
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}